import React from "react";

function Documentation() {
  return (
      <div className="px-2 py-4">
        <p>
          Follow the steps below if you have received an email advising
          additional information is required before we can proceed with your
          Service Request.
        </p>
        <p>
          <b>What you need to do:</b>
        </p>
        <div className="ml-8">
          <ol style={{listStyleType:"numeric"}}>
            <li>
              Download a copy of the Statutory Declaration Form, for the state
              or territory you live in from the links below.
            </li>
            <li>
              Fill in the Statutory Declaration Form confirming the
              circumstances surrounding your service request. You should clearly
              and simply outline the full details of what happened to your
              device, including the date of the incident.
            </li>
            <li>
              Ensure you include the mobile number the device is linked to, so
              we know which Service Request it is for.
            </li>
            <li>
              Once your statutory declaration is completed, please print it off
              and get your signature certified by an authorised witness. You can
              find a list of legally accepted witnesses &gt;here{" "}
              <a className="text-[#337AB7] cursor-pointer" href="https://www.ag.gov.au/Publications/Statutory-declarations/Pages/List-of-authorised-witnesses.aspx">
                https://www.ag.gov.au/Publications/Statutory-declarations/Pages/List-of-authorised-witnesses.aspx
              </a>
            </li>
            <li>
              Return your original signed Statutory Declaration by following one
              of these methods:
            </li>
          </ol>
        </div>
        <div className="pl-[40px]">
          <b>Email</b>
          <br />
          Email the scanned or photographed image to{" "}
          <a className="text-[#337AB7] underline cursor-pointer" href="mailto:stayconnected-documents@team.telstra.com">
            stayconnected-documents@team.telstra.com
          </a>
          <br />
          Please put your name, the associated mobile number and ‘StatDec’ in
          the subject line
          <br />
          <b>Post</b>
          <br />
          Telstra StayConnected
          <br />
          PO Box 4083
          <br />
          Richmond East LPO
          <br />
          VIC, 3121
          <br />
        </div>
        <br />
        <p>
          You will be contacted about the progress of your service request
          within 1-2 business days of your Statutory Declaration Form being
          received.
        </p>
        <p>
          <b>Download a file</b>
          <br />
          Click on one of the links below for your state or territory:
        </p>
        <p>
          &gt;{" "}
          <a className="text-[#337AB7] cursor-pointer" href="https://www.ag.gov.au/Publications/Statutory-declarations/Documents/CommonwealthStatutorydeclarationform.pdf">
            ACT Statutory Declaration Form
          </a>
          <br />
          &gt;{" "}
          <a className="text-[#337AB7] cursor-pointer" href="http://www.jp.nsw.gov.au/Documents/jpninthschedule.pdf">
            NSW Statutory Declaration Form
          </a>
          <br />
          &gt;{" "}
          <a className="text-[#337AB7] cursor-pointer" href="https://nt.gov.au/__data/assets/word_doc/0011/167933/statutory-declaration.docx">
            NT Statutory Declaration Form
          </a>
          <br />
          &gt;{" "}
          <a className="text-[#337AB7] cursor-pointer" href="http://www.courts.qld.gov.au/__data/assets/pdf_file/0004/88555/gen-f-qld-stat-dec.pdf">
            QLD Statutory Declaration Form
          </a>
          <br />
          &gt;{" "}
          <a className="text-[#337AB7] cursor-pointer" href="https://www.sa.gov.au/__data/assets/pdf_file/0011/11432/MR28.pdf">
            SA Statutory Declaration Form
          </a>
          <br />
          &gt;{" "}
          <a className="text-[#337AB7] cursor-pointer" href="http://www.justice.tas.gov.au/__data/assets/pdf_file/0008/181439/Statutory_declaration.pdf">
            TAS Statutory Declaration Form
          </a>
          <br />
          &gt;{" "}
          <a className="text-[#337AB7]  cursor-pointer" href="http://assets.justice.vic.gov.au/justice/resources/655adb6d-a406-40f5-ba66-81fb306b047d/statutory-declaration-form.pdf">
            VIC Statutory Declaration Form
          </a>
          <br />
          &gt;{" "}
          <a className="text-[#337AB7] cursor-pointer" href="https://www.finance.wa.gov.au/cms/uploadedFiles/_State_Revenue/FHOG/Stat_dec.pdf?n=781">
            WA Statutory Declaration Form
          </a>
          <br />
        </p>
      </div>
  );
}

export default Documentation;
