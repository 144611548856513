import React from "react";
import Link from '../link';
import {customerTerms, businessTerms} from '../../constants/scripts'


function Exchangedevice() {
  return (
    <div className="p-4 border border-[#dadada] rounded-md">
      <p className="text-[#414141] font-semibold text-center text-xl pb-[12px]">Screen Replacement</p>
      <div className="text-sm text-[#333333]">
      <p>Screen replacement is a delicate process, and there are specific questions we need to ask you to make sure that your device is eligible.</p>
      <br/>
      
      <strong>
      To check if you are eligible for the screen replacement option you will need to contact us on 13 22 00 (Telstra Business customers 13 20 00).
      </strong>
      <br/>
      <br/>
      <strong>
      If your device is eligible, you may choose to obtain a StayConnected® loan phone while we replace your screen. Unfortunately, tablets are not
      available under this program for loan.
      </strong>
      <br/>
      <br/>
        <strong>
        We’ll provide you with instructions and all the packaging to mail your device to us. When we receive your device, we’ll check and assess it to
        make sure it matches with what you’ve told us. The sooner you send your device in, the sooner we can start on the screen replacement.
        </strong>
        <br/>
        <br/>
        <strong>
        make sure it matches with what you’ve told us. The sooner you send your device in, the sooner we can start on the screen replacement .
Once the screen replacement is completed, will we charge you the $59.00 service request fee to your Telstra bill or your nominated credit card.
        </strong>
        <br/>
        <br/>
        <strong>
        When we return your device, your signature or the signature of someone you trust is required at the time of delivery so we know it is going to
the right person. A signature acknowledges the physical receipt of your device, and we bear no further responsibility in connection with the
delivery of your device. If you miss the door-to-door delivery, your device kit will be kept safe at your local post office waiting for you.
        </strong>
        <br/>
        <br/>
        <strong>
        Please note that if you do not return the loan phone to us within 14 days or it is returned to us damaged, disabled, locked or IMEI blocked, a
DNR Fee will be charged to your Telstra account. [A fee will also be payable if a loan phone case is damaged/not returned.] Screen replacement
devices are excluded from our next day delivery service.
        </strong>
            <br/>
            <br/>
        <strong>
        To help set up your device after the screen replacement, follow the guides included in your device kit.

        </strong>
        <p className='text-[#555] text-sm mt-2'>
        Click on one of the links below for complete Customer Terms:
        </p>
            <div className='my-2'>
                {customerTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
            <p className='text-[#555] text-sm'>Telstra Business customers</p>
            <div className='my-2'>
                {businessTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
      </div>
    </div>
  );
}

export default Exchangedevice;
