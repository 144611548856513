import React from 'react';

function Contactus() {
  return (
    <div className="px-2 py-4">
            <h4>
                <b className="text-lg font-semibold">Need Help</b>
                </h4>
                If you'd like to talk to us:<br/><br/>Email us at <a href="mailto:stayconnected-enquiries@team.telstra.com">stayconnected-enquiries@team.telstra.com.</a><br/><br/>If you rather talk to us, please call us on 132200 and say<br/>"StayConnected" and "TechnicalSupport" when prompted.<br/>Our call center is open between 8am and 9pm every day.
        </div> 
 )
}

export default Contactus