import React from "react";
import Link from '../link';
import {customerTerms, businessTerms} from '../../constants/scripts'


function ReplaceDevice() {
  return (
    <div className="p-4 border border-[#dadada] rounded-md">
      <p className="text-[#414141] font-semibold text-center text-xl pb-[12px]">Replace Your Device</p>
      <div className="text-sm text-[#333333]">
      <p>
      You can count on our <strong>device replacement program with next business day delivery to your doorstep</strong> when you’ve looked everywhere
      for your device but can’t seem locate it!
      </p>
      <br/>
      <p>
      Tell us what happened to your device by 2pm (AEST) the same day, to help us deliver a device to you by the next business day (as long as we
        have the same or a similar device in stock). We will send you a replacement device with guides to help set up the device.
      </p>
      <br/>
        <p>
        You’ll approve <strong>a service request fee of no more than $190.00 </strong>to replace your device, and you’ll know exactly how much you’ll have to
        pay at the time of your request. If you like, we can charge it to your Telstra bill or if you prefer to your nominated credit card.
        </p>
        <br/>
        <p>
        While we try our best to provide a device that matches your current device, <strong>you’ll receive the same or comparable make and model
        (usually refurbished).</strong> It’ll have the same or higher memory and storage power but we can’t promise it’ll be the same colour.
        </p>
        <br/>
        <p>
        Your signature or the signature of someone you trust is required at the time of delivery so we know it is going to the right person. A signature
acknowledges the physical receipt of your device, and we bear no further responsibility in connection with the delivery of your device. If you
miss the door-to-door delivery, your device kit will be kept safe at your local post office waiting for you.
        </p>
        <br/>
        <p>
        To help set up your replacement device, follow the guides included in your device kit.
        </p>
            <br/>
        <p className='text-[#555] text-sm mt-2'>
        Click on one of the links below for complete Customer Terms:
        </p>
            <div className='my-2'>
                {customerTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
            <p className='text-[#555] text-sm'>Telstra Business customers</p>
            <div className='my-2'>
                {businessTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
      </div>
    </div>
  );
}

export default ReplaceDevice;
