import React from "react";

function Preparedevice() {
  return (
    <div className="px-2 py-4">
      <ul>
        <p>
          Read this section if you've requested a repair or device swap (this
          means you have to return your current device to us). Please note: For
          a device swap, you'll need to send your current device to us within 14
          days of receiving your exchange device.
        </p>
        <p>
          The advice below is a guide to help you prepare your current device to
          send to StayConnected. If you need additional support or more
          information, please contact the StayConnected team.
        </p>
        <b>1. Back up your data</b>
        <p>
          Back up any important data on your device before sending it to us.
          This includes backing up photos, contacts and other personal data to
          Google drive, cloud storage such as iCloud or iTunes, or to your
          computer.
          <br />
          <b>Data will not be retained on any device received.</b>
        </p>
        <b>2. Erase your device</b>
        <p>
          After you have backed up your data, its best to erase your device or
          factory reset your device. This is to protect your privacy.{" "}
          <b>
            If your device is damaged to a level where it can not be erased
            before sending it in, it will be wiped once received.
          </b>
        </p>
        <b>3. Unlock security features</b>
        <p>
          You must unlock and disable passwords and security features (e.g. all
          passwords and pattern locks or PIN codes to keep screens unlocked) to
          allow us to process your request. For an Apple device you must also
          turn off the Find My iPhone security feature. Please see the section
          for Turn off Find My iPhone.
        </p>
        <b>4. Send your device only</b>
        <p>
          You must{" "}
          <b>
            remove your SIM card and re-insert the SIM tray back into your
            device.
          </b>{" "}
          SIM cards sent to us will not be returned and will be destroyed.
        </p>
        <p>
          If the device allows, remove the battery, SD (memory) card and all
          accessories including covers. If these components can't be removed or
          are not easily accessible, don't attempt to remove them.{" "}
          <b>
            All personal items including accessories and covers will not be
            returned and destroyed.
          </b>
        </p>
        <b>5. Send in your device at a post office</b>
        <p>
          You will receive StayConnected return labels and packaging as part of
          your request to send in your device to us. We recommend you visit your
          nearest Post Office and keep a copy of your receipt as confirmation.
          <br />
          Once your package is scanned it is tracked throughout its delivery to
          us. You can visit <b>auspost.com.au</b> and{" "}
          <b>enter the first 10 digit of your AP Article Id</b> to track its
          delivery.
        </p>
      </ul>
    </div>
  );
}

export default Preparedevice;
