import React from 'react';
import SpectrumImage from "../assets/spectrum2.png";
import { FaQuestionCircle } from "@react-icons/all-files/fa/FaQuestionCircle";
import { Link } from "react-router-dom"

function Banner() {
  return (
    <div className='pb-4  max-w-[980px]  mx-auto px-4'>
    <div className="flex items-center justify-between my-[10px] max-w-[980px] mx-auto">
      <div>
        <a
          className="flex items-center gap-1"
          target="_blank"
          rel="noreferrer"
          href="https://www.telstra.com.au/"
        >
          <img src="https://yourservicerequest.stayconnected.telstra.com.au/assets/img/telstra-logo.png" alt="logo"/>
          <span id="headingSalutationText">Personal</span>
        </a>
      </div>
        <Link to="/help" target="_blank">
           <div className="flex gap-1 items-center cursor-pointer">
            Help <FaQuestionCircle />
           </div>
        </Link>
    </div>
    <div className='absolute'>
      <img src={SpectrumImage} alt="banner"/>
    </div>
    <div className="text-[#1964C8] text-[24px] pt-4 mt-12">
    StayConnected<sup>®</sup>
    </div>
  </div>
  )
}

export default Banner