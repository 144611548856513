import React from 'react';
import Link from '../link';
import {customerTerms, businessTerms} from '../../constants/scripts'


function Thingstoknow() {
  return (
    <div className="px-2 py-4">
        <p className='text-[#555] text-sm'>Click on one of the links below for complete Customer Terms:</p>
        <div className='my-2'>
                {customerTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
            <p className='text-[#555] text-sm'>Telstra Business customers</p>
            <div className='my-2'>
                {businessTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
        </div>
    </div>
  )
}

export default Thingstoknow