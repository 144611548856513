import React from "react";
import Link from '../link';
import { Link as RouterLink } from "react-router-dom";
import {customerTerms, businessTerms} from '../../constants/scripts'


function Exchangedevice() {
  return (
    <div className="p-4 border border-[#dadada] rounded-md">
      <p className="text-[#414141] font-semibold text-center text-xl pb-[12px]">Exchange Your Device</p>
      <div className="text-sm text-[#333333]">
      <p>
        When you need an exchange device because you have no other dependable
        device back-up plan, you can count on our <strong>device exchange program with
        next business day delivery to your doorstep.</strong>
      </p>
      <br/>
      <p>
       <strong>We’ll send you a device first</strong> to exchange with the one you have on hand,
        you’ll set the device up, <strong>then you’ll mail your original device to us.</strong>
        Tell us what happened to your device by 2pm (AEST) the same day, to help
        us deliver a device to you by the next business day as long as the
        device is in stock.
      </p>
      <br/>
        <p>
          You’ll know exactly how much you’ll have to pay at the time of your
          service request, and it <strong>won’t be more than $190.00. If you like, we’ll
          charge it to your Telstra bill or if you prefer to your credit card.</strong>
        </p>
        <br/>
        <strong>
          While we try our best to provide a device that matches your current
          device, you’ll receive the same or comparable make and model (usually
          refurbished). It’ll have the same or higher memory and storage power
          but we can’t promise it’ll be the same colour.
        </strong>
        <br/>
        <br/>
        <strong>
          Your signature or the signature of someone you trust is required at
          the time of delivery so we know it is going to the right person. A
          signature acknowledges the physical receipt of your device, and we
          bear no further responsibility in connection with the delivery of your
          device. If you miss the door-to-door delivery, your device kit will be
          kept safe at your local post office waiting for you.
        </strong>
        <br/>
        <br/>
        <strong>
          Please remember: you have 14 days from accepting the delivery to mail
          in your original device to us to avoid paying fees. Use the prepaid
          return label emailed to you or packaging included in your device kit
          to promptly mail it in. If we do not receive your device, you will be
          charged a device non return fee which is equal to the fair market
          value of your original device at that point in time.
        </strong>
            <br/>
            <br/>
            <p>To help you prepare your original device for mail-in, follow the guides included in your device kit or in the <RouterLink to="/help" target="_blank"><span className="hover:underline text-[#337AB7]">Online Help</span></RouterLink> section on this portal.</p>
        <p className='text-[#555] text-sm mt-2'>
        Click on one of the links below for complete Customer Terms:
        </p>
            <div className='my-2'>
                {customerTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
            <p className='text-[#555] text-sm'>Telstra Business customers</p>
            <div className='my-2'>
                {businessTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
      </div>
    </div>
  );
}

export default Exchangedevice;
