import React from 'react'
import Link from './link';
import {customerTerms, businessTerms} from '../constants/scripts'
import {ReactComponent as UpArrow} from '../assets/uparrow.svg'
import {ReactComponent as DownArrow} from '../assets/downarrow.svg'


function Thingstonote({onChange, isOpen=false}) {
  return (
    <div className='text-sm bg-[#F5F5F5] text-[#555] mt-4 cursor-pointer' onClick={()=>onChange()}>
       <div className={`text-base py-3 pr-2 ${isOpen ? 'bg-[#4398F1] text-white'  : 'bg-[#DADADA] text-[#555]'} flex justify-between items-center`}>
     
            <p className='flex-grow text-center '>Things you need to know</p>
            {
             !isOpen ? <DownArrow  className='ml-auto cursor-pointer'/> : <UpArrow className='ml-auto cursor-pointer' />  
            }
        </div>
        {isOpen && <div className="flex items-center justify-center"> <span class="caret caret-reversed mx-auto"></span> </div>}
        {isOpen && <div className='px-[20px] py-[30px]'>
            
            <p>
            You may exchange, replace or repair your device twice in each 12 month period, subject to the payment of a service fee of up to $190.00 each time or a screen replacement fee of $59.00 for eligible handsets and tablets. An exchanged or replaced device is usually a refurbished device that is the same model as your current device or a similar device. If you have the right to a replacement under a warranty or consumer guarantee (excluding screen replacement), you won't be charged a service fee. You must return your existing device within 14 days of receiving your replacement device, or pay a Device Non Return Fee. You may also be charged an Inoperable Device Fee if you return a locked or security-enabled device.
            </p>
            <p className='mt-2'>
            Summary of StayConnected Key Customer Terms
            </p>
            <ul className='list-disc ml-6'>
                <li>
                Turn off security feature, e.g. disable Find my iPhone feature (or be charged an Inoperable Device Fee).
                </li>
                <li>
                Service Requests are not accepted if a customer's account is in arrears.
                </li>
                <li>
                Service Requests can be denied if we reasonably suspect fraud or misuse.
                </li>
                <li>
                Customers must provide any information reasonably requested during the service request process.
                </li>
            </ul>
            <div className='my-2'>
                {customerTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
            <p className='text-[#555] text-sm'>Telstra Business customers</p>
            <div className='my-2'>
                {businessTerms?.map(({title, url}) =><Link title={title} url={url}/>)}
            </div>
            <p>Need Help </p>
            <p>Call 13 2200 (or 13 2000 for Telstra business customers) and say 'StayConnected' (a free call from most Telstra home phones and Telstra mobile phones within Australia). If you're overseas you can call us on +61 439 125 109 (a free call from a Telstra mobile service).</p>
            <p className='mt-1'>Apple and iPhone are trade marks of Apple Inc. registered in the U.S. and other countries.
            ™ are trade marks and ® are registered trade marks of Telstra Corporation Limited ABN 33 051 775 556.</p>
        </div>}
    </div>
  )
}

export default Thingstonote