import React from "react";

function Footer() {
  return (
    <div className="bg-[#F4F4F4] py-4 mt-6 text-xs text-[#333333]">
      <div className="max-w-[980px]  flex flex-col md:flex-row justify-between mx-auto px-4">
        <div className="mb-2">
            <div class="social-icons mb-1">
            <a href="https://www.facebook.com/Telstra24x7" target="_blank" rel="noreferrer" class="icon facebook"> </a>
            <a href="https://twitter.com/telstra" target="_blank" rel="noreferrer" class="icon twitter"> </a>
            <a href="http://www.youtube.com/user/TelstraCorp" target="_blank" rel="noreferrer" class="icon youtube"> </a>
            <a href="https://plus.google.com/+Telstra" target="_blank" rel="noreferrer" class="icon gplus"> </a>
            </div>
        </div>
        <div className="flex flex-col">
          <a
            className="font-bold mb-[4px] hover:underline"
            href="https://www.telstra.com.au/sitemap"
            target="_blank"
            rel="noreferrer"
          >
            Telstra.com sitemap
          </a>
          <a
            className="mb-[4px] hover:underline"
            href="https://www.telstra.com.au/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            Contact us
          </a>
          <a
            className="mb-[4px] hover:underline"
            href="http://www.telstra.com.au/store-locator"
            target="_blank"
            rel="noreferrer"
          >
            Find a store
          </a>
          <a
            className="mb-[4px] hover:underline"
            href="http://careers.telstra.com/"
            target="_blank"
            rel="noreferrer"
          >
            Careers
          </a>
          <a
            className="mb-[8px] hover:underline"
            href="https://www.telstra.com.au/partners-and-causes/"
            target="_blank"
            rel="noreferrer"
          >
            Partners and Causes
          </a>
        </div>
        <div className="flex flex-col">
          <a
            className="font-bold mb-[4px] hover:underline"
            href="http://www.telstra.com.au/aboutus"
            target="_blank"
            rel="noreferrer"
          >
            About us
          </a>
          <a
            className="mb-[4px] hover:underline"
            href="http://www.telstrawholesale.com.au/"
            target="_blank"
            rel="noreferrer"
          >
            Telstra Wholesale
          </a>
          <a
            className="mb-[4px] hover:underline"
            href="http://www.telstraglobal.com/"
            target="_blank"
            rel="noreferrer"
          >
            Telstra Global
          </a>
          <a
            className="mb-[8px] hover:underline"
            href="http://www.telstra.com.au/personal/digital-story"
            target="_blank"
            rel="noreferrer"
          >
            Telstra Digital
          </a>
        </div>
        <div className="flex flex-col">
          <a
            className="font-bold mb-[4px] hover:underline"
            href="https://www.telstra.com.au/consumer-advice"
            target="_blank"
            rel="noreferrer"
          >
            Consumer Advise
          </a>
          <a
            className="mb-[4px] hover:underline"
            href="https://www.telstra.com.au/help/critical-information-summaries"
            target="_blank"
            rel="noreferrer"
          >
            Critical Information Summaries
          </a>
          <a
            className="mb-[4px] hover:underline"
            href="http://www.telstra.com.au/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of use
          </a>
          <a
            className="mb-[4px] hover:underline"
            href="https://www.telstra.com.au/privacy/privacy-statement"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
