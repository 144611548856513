
import React from "react";
import {ReactComponent as Menu} from '../assets/menu.svg'

const NavBar = ({handleMenu, isOpen}) => {
  
  return (
    <header className="flex-col bg-[#DADADA] text-[#767676] text-sm">
            <div className="md:flex hidden items-center px-4 py-[10px] max-w-[980px] mx-auto text-[#767676]">

              <a href="http://www.telstra.com.au/?direct" target="_blank" rel="noreferrer" className="pr-[15px]">
                Telstra
              </a>
              <a href="https://www.telstra.com.au/personal" target="_blank" rel="noreferrer" className="px-[15px]">
                Personal
              </a>
              <a
                href="https://www.telstra.com.au/small-business"
                target="_blank"
                rel="noreferrer"
                className="px-[15px]"
              >
                Small Business
              </a>
              <a
                href="https://www.telstra.com.au/business-enterprise"
                target="_blank"
                rel="noreferrer"
                className="px-[15px]"
              >
                Business & Enterprise
              </a>
              <a
                href="https://www.telstra.com.au/telstra-health"
                target="_blank"
                rel="noreferrer"
                className="px-[15px]"
              >
                Health
              </a>
              <a
                href="https://www.telstra.com.au/tv-movies-music"
                target="_blank"
                rel="noreferrer"
                className="px-[15px]"
              >
                Sport & Entertainment
              </a>
            </div>
            <div className="md:hidden flex items-center justify-between max-w-[980px] mx-auto px-4 py-4 text-[#767676]">
              <div className="flex flex-row justify-between items-center w-full">
                <a href="https://www.telstra.com.au/?direct" target="_blank" rel="noreferrer">
                    Telstra
                </a>  
                  <Menu onClick={handleMenu} className="cursor-pointer" />
              </div>
              </div>
           
              <div className={`${isOpen ? "flex" : "hidden"} text-sm border md:hidden border-[#e7e7e7] flex-col px-4 transition-all duration-200 ease-out pb-4`}>        
                  <a className="py-[10px]" href="https://www.telstra.com.au/personal" target="_blank" rel="noreferrer">
                    Personal
                  </a>
                  <a
                    className="py-[10px]"
                    href="https://www.telstra.com.au/small-business"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Small Business
                  </a>
                  <a
                    className="py-[10px]"
                    href="https://www.telstra.com.au/business-enterprise"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Business & Enterprise
                  </a>
                  <a
                    className="py-[10px]"
                    href="https://www.telstra.com.au/telstra-health"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Health
                  </a>
                  <a
                    className="py-[10px]"
                    href="https://www.telstra.com.au/tv-movies-music"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sport & Entertainment
                  </a>
              </div> 
            
    </header>
  );
};

export default NavBar;