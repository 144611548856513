import React,{useState} from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import NavBar from './components/navbar';
import Landing from './pages/landing';
import Help from './pages/help';
import Footer from './components/footer';
import Banner from './components/banner';
import './App.css'; 

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const handleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <HashRouter>
        <NavBar handleMenu={handleMenu} isOpen={isOpen}/>
        <Banner/>
        <Routes>
          <Route path="/" element={<Landing />}></Route>
          <Route path="/help" element={<Help />}></Route>
        </Routes>
        <Footer/>
      </HashRouter>  
    </div>
  );
}

export default App;
