import React from 'react'

const Card = ({name, title, description, icon, handleClick}) => {
  
  return (
    <div className='w-full border border-[#dadada] rounded-md p-[20px] mb-2 text-[#555] relative h-auto'>
      <div className='flex flex-col items-center justify-center gap-[20px] '>
        <div className='flex flex-col items-center justify-center gap-[10px] text-[#00AAF3] text-lg'>
            <img width={"80px"} height={"80px"} src={icon} alt="placeholder" />
            <div className='text-base font-semibold'>{title}</div>
        </div>
        <p className='text-justify text-sm lineh'>{description}</p>
          <div className='h-4 pb-4'></div>
          <button className='bg-[#00AAF3] text-sm text-white w-[160px] pt-[6px] pb-[8px] left-0 right-0 mb-[6px] mx-auto absolute bottom-1' onClick={()=>handleClick(name)}> More Information</button>
      </div>
    </div>
  )
}

export default Card