import React from 'react'
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";



function Accordian({onChange, isOpen=false, title="default", children}) {
  return (
    <div className={`mx-4 border border-[#dadada] ${isOpen && "rounded-b-md"}  text-sm  text-[#555] mt-2 cursor-pointer`} onClick={()=>onChange()}>
       <div className={` text-base py-3 pr-2 bg-[#0099F8] text-white flex justify-between items-center`}>
     
            <p className='flex-grow text-xl pl-4'>{title}</p>
            {
             !isOpen ? <FaChevronDown  className='ml-auto cursor-pointer mr-2'/> : <FaChevronUp className='ml-auto cursor-pointer mr-2' />  
            }
        </div>
        {isOpen && <div className="flex items-center justify-center"> <span class="caret caret-reversed mx-auto"></span> </div>}
        {isOpen && 
        <div className='px-[20px] pb-4 text-sm'>
            {children}
        </div>
        }
    </div>
  )
}

export default Accordian