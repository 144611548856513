import React from "react";

function Fmip() {
  return (
    <div className="px-2 py-4">
        <p>
          Follow the steps below to disable the Find My iPhone feature . When
          you turn off Find My iPhone, you will receive an email from Apple
          notifying you that the feature is disabled on your device.
        </p>
        <p>
          We do our best to help our customers turn off Find My iPhone, however
          you are still responsible for making sure the feature is turned off.
          <br />
          Until this happens your device is linked to your Apple account, we
          cannot accept your device, and you will be charged an Inoperable
          Device Fee.
        </p>
        <div class="col-sm-6">
          <b>I can use my device</b>
          <br />
          1. From the home screen, tap Settings &gt; Apple ID &gt; iCloud
          <br />
          2. To disable Find My iPhone, tap the green toggle to
          <br />
          Off (grey).
          <br />
          3. Enter your Apple ID password, tap Turn Off.
        </div>
        <div class="col-sm-6">
          <b>I can't use my Apple device</b>
          <br />
          1. Log in to your iCloud account (www.icloud.com)
          <br />
          2. Click Find My iPhone &gt; All Devices
          <br />
          3. Identify the device (Active devices appear with a<br />
          green dot, while inactive devices have a gray dot)
          <br />
          4. Click on the name of the device.
          <br />
          5. Click Remove from Account. (can only be removed
          <br />
          if showing as inactive)
        </div>
      </div>
  );
}

export default Fmip;
