import React,{useState} from 'react'
import Card from '../components/card'
import Thingstonote from '../components/thingstonote'
import {services} from '../constants/scripts'
import {ReactComponent as Chat} from '../assets/chat.svg'
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import Exchangedevice from '../components/program/exchangedevice';
import ReplaceDevice from '../components/program/replacedevice';
import Replacement from '../components/program/replacement'

function Landing() {
  const [showNotes, setShowNotes] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState(undefined)

  const handleSelection=(program)=>{
    setSelectedProgram(program);                  
    document.getElementById("top").scrollIntoView({ behavior: 'smooth' });
  }
  
  return (
    <div className='pb-4  max-w-[980px]  mx-auto px-4'>
      <div id="top"></div>
        
        <div className='border border-[#d1d3d4] '>
            <div className='border-t-[48px] border-[#00AAF3] px-6 pb-[30px]'>
            <div className='border border-[#dadada] rounded-md p-[20px] my-5'>
              <p className='text-[#0399F8] text-[22px] font-bold'>Important Notice: Telstra StayConnected</p>
              <p className='text-[#333] text-[16px] pb-[24px] pt-[8px]'>To submit a new service request, resume an existing service request, or for any enquiries, please contact us at 132 200 and say “Stay Connected” at the voice prompt.</p>
              <p className='text-[#333] text-[16px]'>Our team is ready to assist you Monday to Sunday, 8am to 9pm AEST/AEDST.<br/>Thank you.</p>
            </div>
          
            {/* <div className='flex flex-row justify-center items-center max-w-fit cursor-pointer p-[10px] bg-[#00AAF3] rounded-[5px] text-white text-sm mb-[30px]'>
              <Chat className="mr-[10px]"/> Chat with StayConnected Support
            </div> */}
            {!selectedProgram && <div className='flex flex-col md:flex-row space-x-2 mx-auto items-center md:items-stretch'>
                {services?.map(({key, title, description, icon}) => 
                ( 
                    <Card name={key} title={title} description={description} icon={icon} handleClick={(program)=>{handleSelection(program)}}/>
                ))
                }
            </div>
            }
            {
              selectedProgram === 'exchange' ? <Exchangedevice/> : selectedProgram === 'replace' ? <ReplaceDevice/> : selectedProgram === 'replacement' ?  <Replacement/> : <></>
            }
            {
              selectedProgram && <div className='flex mt-6 justify-end'>
                <button className='flex flex-row bg-[#1964C9] text-white pr-3 pl-4 py-2 items-center cursor-pointer' onClick={()=>{
                  setSelectedProgram(undefined);
                  document.getElementById("top").scrollIntoView();
                  }}><span>Back</span> <FaChevronRight className='mt-[2px] ml-2'/></button>
              </div>
            }
            </div>
        </div>
        <div>
            <Thingstonote isOpen={showNotes} onChange={()=>{setShowNotes(!showNotes)}}/>
        </div> 
</div>

)}

export default Landing