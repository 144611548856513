export const services = [
{
    "key":"exchange",
    "icon": "https://yourservicerequest.stayconnected.telstra.com.au/assets/img/exchange.png",
    "title": "Exchange your device with us",
    "description": "No one wants to be out of the loop! Our goal is to help keep you connected to your family and friends by getting an exchange device to your doorstep asap. Once you’ve approved or paid a service request fee of up to $190.00, we’ll send you an exchange device. Customers who lodge a request by 2pm (AEST) usually receive their exchange device by the next business day. Then, take some time to set up your exchange device and transfer data from your old device. Mail your original device to us within 14 days to avoid paying a Device Non-Return Fee (being the fair market value of the exchange device and any shipping costs) (DNR Fee). And, that’s it. Simple - job done!"
},
{
    "key":"replace",
    "icon": "https://yourservicerequest.stayconnected.telstra.com.au/assets/img/replace.png",
    "title": "Let Us Replace Your Device",
    "description": "We don’t want you to miss a single post or tweet! So, our mission is to get a replacement device to you as quick as we can. Once you’ve approved or paid a service request fee of up to $190.00, we’ll send you a replacement device. Customers who lodge a request by 2pm (AEST) will usually receive their replacement device by the next business day."
},
{
    "key":"replacement",
    "icon": "https://yourservicerequest.stayconnected.telstra.com.au/assets/img/screen-repair.png",
    "title": "Screen Replacement by Us",
    "description": "Need help with your mobile phone screen? Our screen-only replacement service at an affordable $59.00 is a great option to get your screen back to its smooth and shiny best. You can ask for a StayConnected® loan phone to tide you over while we’re replacing your screen – (which you will need to return to us within 14 days to avoid paying a Device Non-Return Fee). You also need to make sure that it is not damaged, disabled or IMEI blocked or you will be charged a device non-return fee. Screen replacement devices are excluded from our next day delivery service."
}
]

export const customerTerms = [
    {
    "title": "StayConnected Advanced Critical Information Summary",
    "url": "https://www.telstra.com.au/help/critical-information-summaries/personal/mobile/stay-connected-advanced/stay-connected-advanced"
    },
    {
    "title": "StayConnected Advanced Customer Terms",
    "url": "chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://www.telstra.com.au/content/dam/tcom/personal/help/pdf/telstra-stayconnected-advanced-terms-and-conditions.pdf"
    }
]

export const businessTerms = [
    {
    "title": "StayConnected Advanced for Business Critical Information Summary",
    "url": "chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://www.telstra.com.au/content/dam/tcom/personal/help/pdf/cis-business/mobile/business-critical-information-summary-stay-connected-advanced.pdf"
    },
    {
    "title": "StayConnected Advanced for Business Customer Terms",
    "url": "https://www.telstra.com.au/content/dam/tcom/personal/help/pdf/cis-business/mobile/stayconnected-advanced-small-business-terms-and-conditions.pdf"
    },
    {
    "title": "Privacy",
    "url": "https://www.telstra.com.au/privacy"
    }
]