import React from "react";

function Faq() {
  return (
    <div className="px-2 py-4">
      <ul style={{ listStyleType: "disc" }}>
       
        <li>
          <b>
            How much is the service fee if I want to swap or replace my device?
          </b>
          <p>
            You will be charged a service fee of up to $190 (depending on your
            device make and model). A service fee is charged each
            time you request to swap or replace your device for a refurbished
            device of the same or similar model.
          </p>
          <p>
            There is no cost to you when your device is covered by warranty.
          </p>
        </li>
        <li>
          <b>How do I pay for my service fee?</b>
          <p>
            You can choose to have your service fee charged to your Telstra bill
            (the easiest way) or to your credit card, which may include credit
            card surcharge fees.
          </p>
        </li>
        <li>
          <b>
            How quickly will you ship my device to me once I submit my service
            request?
          </b>
          <p>
            To receive your replacement device by next business day, device must
            be in stock and ready to ship and the request must be submitted and
            approved by 2pm AEST.
          </p>
        </li>
        <li>
          <b>How do I track the delivery of my device?</b>
          <p>
            Once we dispatch your device, you'll receive an email with details
            of its delivery and how to track it online.
          </p>
        </li>
        <li>
          <b>Does my delivery need to be signed for?</b>
          <p>
            Yes, for security reasons a signature is required on delivery. You
            can ask someone else to sign and take delivery on your behalf but
            you are still responsible for your device from the point of receipt.
            <br />
          </p>
          <p>
            If no one is available when delivery is attempted, your device will
            be delivered to the nearest Post Office or collection point. The
            courier will leave a card with collection details and you'll need to
            present this card and photo ID with proof of address to pick up your
            device.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default Faq;
