import React from 'react'
import Accordian from '../components/accordian'
import Contactus from '../components/help/contactus';
import Thingstoknow from '../components/help/thingstoknow';
import Faq from '../components/help/faq';
import Preparedevice from '../components/help/preparedevice';
import Fmip from '../components/help/fmip';
import Documentation from '../components/help/documentation';

function Help() {
    const [selectedAccordian, setSelectedAccordian] = React.useState(null);

    const handleChange = (currentAccordian) => {
        currentAccordian === selectedAccordian ?  setSelectedAccordian(null) : setSelectedAccordian(currentAccordian);
    }

  return (
    <div className='mx-auto max-w-[980px] pb-4'>
         <div className='border border-[#dadada] rounded-md p-[20px] my-5 max-w-[945px] mx-4 mb-4'>
              <p className='text-[#0399F8] text-[22px] font-bold'>Important Notice: Telstra StayConnected</p>
              <p className='text-[#333] text-[16px] pb-[24px] pt-[8px]'>To submit a new service request, resume an existing service request, or for any enquiries, please contact us at 132 200 and say “Stay Connected” at the voice prompt.</p>
              <p className='text-[#333] text-[16px]'>Our team is ready to assist you Monday to Sunday, 8am to 9pm AEST/AEDST.<br/>Thank you.</p>
         </div>
        <Accordian title="Contact us" isOpen={selectedAccordian === "Contact us"} onChange={()=>handleChange('Contact us')}>
            <Contactus/>
        </Accordian>
        <Accordian title="Things you need to know" isOpen={selectedAccordian === "Things you need to know"} onChange={()=>handleChange('Things you need to know')}>
            <Thingstoknow/>
        </Accordian>
        <Accordian title="FAQs about your device request" isOpen={selectedAccordian === "FAQs about your device request"} onChange={()=>handleChange('FAQs about your device request')}>
            <Faq/>
        </Accordian>
        <Accordian title="Prepare your device for mail-in" isOpen={selectedAccordian === "Prepare your device for mail-in"} onChange={()=>handleChange('Prepare your device for mail-in')}>
            <Preparedevice/>
        </Accordian>
        <Accordian title="Turn off Find My iPhone" isOpen={selectedAccordian === "Turn off Find My iPhone"} onChange={()=>handleChange('Turn off Find My iPhone')}>
            <Fmip/>
        </Accordian>
        <Accordian title="Upload / Need Documentation" isOpen={selectedAccordian === "Upload / Need Documentation"} onChange={()=>handleChange('Upload / Need Documentation')}>
            <Documentation/>
        </Accordian>
    </div>
  )
}

export default Help