import React from 'react'
import {ReactComponent as RightArrow} from '../assets/rightarrow.svg'

function Link({title, url}) {
  return (
    <div className='flex flex-row items-center'>
        <RightArrow/>
        <a className='ml-1 text-[#2D86CA] text-sm cursor-pointer' href={url} target="_blank" rel="noreferrer">{title}</a>
    </div>
  )
}

export default Link